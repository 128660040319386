<template>
  <base-section id="aToZ">
    <v-container>
      <v-row>
        <v-col
          cols="2"
          class="text-center hidden-sm-and-down"
        />

        <v-col
          cols="12"
          md="8"
        >
          <base-subheading
            size="display-1"
            title="A To Z"
            space="0"
          />

          <base-title
            class="primary--text"
            title="Pub List"
            tag="div"
          />

          <base-body>
            Our comprehensive list of every bar and pub on our site, listed
            alphabetically. We have over 100 bars and pubs on the site, use the
            search provided to find a bar of your favourite
          </base-body>

          <v-row>
            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              cols="4"
            >
              <base-title
                :title="'Total Pubs: ' + filteredList.length"
                class="text-uppercase"
                space="3"
              />
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
            >
              <base-text-field
                v-model="filterText"
                label="Search"
                :is-clearable="true"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="(pub, i) in filteredList"
              :key="i"
              cols="12"
              md="6"
            >
              <div @click="showDetail(pub.id)">
                <base-list-item
                  :text="pub.name"
                  :selected="selected == pub.id"
                  :clickable="true"
                />
              </div>
              <transition name="fade">
                <div
                  v-if="selected == pub.id"
                  style="position: relative"
                  :style="!$vuetify.breakpoint.mdAndDown ? (i%2 == 0 ? '' : 'left: -107%') : ''"
                >
                  <v-row>
                    <v-col>
                      <pub-detail-map :pub="pub" />
                    </v-col>
                  </v-row>
                </div>
              </transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PubDetailMap from '../../components/map/PubDetailMap.vue'

  export default {
    name: 'SectionAToZ',
    components: {
      PubDetailMap,
    },
    data: () => ({
      filterText: '',
      selected: null,
    }),
    computed: {
      ...mapGetters(['alphaBeticalPubs']),
      filteredList () {
        if (this.filterText) {
          return this.alphaBeticalPubs.filter(
            (pub) =>
              pub.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1,
          )
        } else {
          return this.alphaBeticalPubs
        }
      },
    },
    methods: {
      showDetail (pubId) {
        this.selected = this.selected ? null : pubId
      },
    },
  }
</script>

<style scoped>
@import "https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css";
.fade-enter-active {
  transition: opacity 0.5s ease;
}
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
