<template>
  <v-card :width="$vuetify.breakpoint.mdAndUp && !fixedWidth ? '200%' : '100%'">
    <v-card-subtitle v-if="pub">
      {{ pub.address }}
    </v-card-subtitle>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <div
          id="detailMap"
          class="basemap black--text ml-2"
          style="height: 400px"
        />
      </v-col>
      <v-col
        v-if="pub"
        cols="12"
        md="6"
      >
        <div
          v-if="pub.contactNumber !== null"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          <a :href="`tel:${pub.contactNumber}`">{{ pub.contactNumber }}</a>
        </div>
        <div
          v-if="pub.contactEmail !== null"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          <a :href="`mailto:{{pub.contactEmail}}`">Contact Email</a>
        </div>
        <div
          v-if="pub.website !== null"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          <a :href="pub.website">Pub Website</a>
        </div>
        <div
          v-if="pub.outdoorSeating === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Outdoor Seating
        </div>
        <div
          v-if="pub.food === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Serving Food
        </div>
        <div
          v-if="pub.craftBeer === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Craft Beer
        </div>
        <div
          v-if="pub.whiskeyBar === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Whiskey Bar
        </div>
        <div
          v-if="pub.liveMusic === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Live Music
        </div>
        <div
          v-if="pub.cocktails === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Cocktails
        </div>
        <div
          v-if="pub.liveSport === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Live Sport
        </div>
        <div
          v-if="pub.bigScreen === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Big Screen
        </div>
        <div
          v-if="pub.lateLicence === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Late Licence
        </div>
        <div
          v-if="pub.dj === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Dj
        </div>
        <div
          v-if="pub.nightclub === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Nightclub
        </div>
        <div
          v-if="pub.hotel === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Hotel
        </div>
        <div
          v-if="pub.ageLimit === 0"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Age Limit {{ pub.ageLimit }}
        </div>
        <!-- <div
          v-if="pub.dateNight === 2"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          Date Night {{ pub.dateNight }}
        </div> -->
        <div
          v-if="pub.description !== null"
          class="text-subtitle-1"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-2'"
        >
          From the Owner: <v-card-text>{{ pub.description }}</v-card-text>
        </div>
        <div v-if="!pub.address">
          Info not currently available
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import mapboxgl from 'mapbox-gl'
  export default {
    name: 'PubDetailMap',
    props: {
      pub: null,
      fixedWidth: {
        type: Boolean,
        default: false,
      },
      highlight: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        accessToken:
          'pk.eyJ1IjoiY29ya3B1Ymd1aWRlIiwiYSI6ImNrcDVzNWJodjA0bngydW9meWYxOHMyM3MifQ.rY7zSF8FzujFNUdKo0VzHw',
        map: null,
        marker: null,
      }
    },
    watch: {
      pub () {
        this.displayPub()
      },
    },
    mounted () {
      if (this.pub) {
        this.displayPub()
      }
    },
    methods: {
      displayPub () {
        mapboxgl.accessToken = this.accessToken

        this.map = new mapboxgl.Map({
          container: 'detailMap',
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [-8.4766, 51.8985],
          maxBounds: [
            [-8.49916, 51.889],
            [-8.4466, 51.912],
          ],
        })
        this.marker = new mapboxgl.Marker()
          .setLngLat([this.pub.lng, this.pub.lat])
          .setPopup(
            new mapboxgl.Popup({
              className: 'apple-popup',
              scale: 0.1,
            }).setHTML(this.pub.name),
          )
          .addTo(this.map)
        this.scrollToPub()
      },
      scrollToPub () {
        if (this.highlight) {
          this.marker.getElement().click()
        }
        this.marker
          .getElement()
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
      },
    },
  }
</script>

<style scoped>
.v-card {
  max-width: 200%;
}

.basemap {
  border-style: solid;
  border-radius: 3px;
}
</style>
